<template>
    <!-- :fullscreen="$vuetify.breakpoint.mdAndDown" -->
  <v-dialog v-model="dialog" width="600px">
    <v-card color="teal lighten-5">
      <v-card-title class="teal lighten-5"> </v-card-title>

      <v-card-text>
        <div class="d-flex">
          <v-sheet
            color="light-blue darken-4"
            elevation="1"
            width="200"
            height="45"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            >บน</v-sheet
          >
          <v-sheet
            color="deep-orange darken-4"
            elevation="1"
            width="200"
            height="45"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            >ล่าง</v-sheet
          >
          <v-sheet
            color="teal darken-4"
            elevation="1"
            width="200"
            height="45"
            class="text-center d-flex flex-column align-center justify-center"
            dark
            >3 ตัวหน้า</v-sheet
          >
        </div>
        <div v-for="n in 11" v-bind:key="n">
          <v-divider></v-divider>
          <div class="d-flex">
            <v-sheet
              color="light-blue lighten-5"
              elevation="1"
              width="200"
              height="45"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center"
              @click="ChangeBetType(top_items[n - 1].value)"
              >{{ top_items[n - 1].text }}</v-sheet
            >
            <v-sheet
              color="brown lighten-5"
              elevation="1"
              width="200"
              height="45"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center"
              @click="ChangeBetType(down_items[n - 1].value)"
              >{{ down_items[n - 1].text }}</v-sheet
            >
            <v-sheet
              color="teal lighten-5"
              elevation="1"
              width="200"
              height="45"
              class="text-center font-weight-bold d-flex flex-column align-center justify-center grey--text"
              @click="ChangeBetType(front_items[n - 1].value)"
              >{{ front_items[n - 1].text }}</v-sheet
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    Show(BtType) {
      this.dialog = true;
      this.BtType = BtType;
    },
    ChangeBetType(BtType) {
      if (BtType == "0") return;
      this.$emit("OnChangeBetType", BtType);
      this.dialog = false;
    },
  },
  data: () => ({
    dialog: false,
    BtType: "1",
    top_items: [
      { text: "3 ตัวบน", value: "1" },
      { text: "3 ตัวโต๊ด", value: "2" },
      { text: "2 ตัวบน", value: "3" },
      { text: "คู่โต๊ด", value: "5" },
      { text: "พวง", value: "7" },
      { text: "จมบนหน้า", value: "9" },
      { text: "จมบนกลาง", value: "10" },
      { text: "จมบนท้าย", value: "11" },
      { text: "ลอยบน", value: "14" },
      { text: "คู่หน้า", value: "16" },
      { text: "คู่ถ่าง", value: "17" },
    ],

    down_items: [
      { text: "2 ตัวล่าง", value: "4" },
      { text: "ลอยล่าง", value: "15" },
      { text: "จมล่างหน้า", value: "12" },
      { text: "จมล่างท้าย", value: "13" },
      { text: "หมุน", value: "8" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
      { text: "", value: "0" },
    ],

    front_items: [
      { text: "3 ตัวบน", value: "18" },
      { text: "3 ตัวโต๊ด", value: "19" },
      { text: "2 ตัวบน", value: "20" },
      { text: "จมบนหน้า", value: "21" },
      { text: "จมบนกลาง", value: "22" },
      { text: "จมบนท้าย", value: "23" },
      { text: "ลอยบน", value: "24" },
      { text: "คู่หน้า", value: "25" },
      { text: "คู่ถ่าง", value: "26" },
      { text: "พวง", value: "27" },
      { text: "คู่โต๊ด", value: "28" },
    ],
  }),
};
</script>

<style></style>
