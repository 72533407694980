<template>
  <div>
    <div height="25dvh">
      <v-app-bar dense fixed app color="cyan darken-4" dark>
        <v-btn icon to="/menu_data">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>ตั้งเจ้ามือ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-app-bar>
      <MenuTab @onTabChange="onTabChange" />
    </div>

    <component v-bind:is="component" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Data/Export/Bookie/Genaral/Datatable";
import HPByBookie from "@/components/Data/Export/Bookie/HPByBookie/Datatable";
import HPByNumber from "@/components/Data/Export/Bookie/HPByNumber/Datatable";
import CurrentTable from "@/components/Cus/Line/Current/Datatable";
import AccountTable from "@/components/Cus/Line/Account/Datatable";
import MenuTab from "@/components/Data/Export/Bookie/Layout/Menu_Tab";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    GenaralTable,
    CurrentTable,
    HPByBookie,
    HPByNumber,
    AccountTable,
    MenuTab,
  },
  mounted() {
    this.ckLogin();
    this.bc_items[0].text = sessionStorage.getItem("host_id");
  },
  data: () => ({
    component: "GenaralTable",

    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
    ],
  }),

  methods: {
    onTabChange(id) {
      switch (id) {
        case 1:
          this.component = GenaralTable;
          window.scrollTo(0, 0);
          break;
        case 2:
          this.component = CurrentTable;
          window.scrollTo(0, 0);
          break;
        case 3:
          this.component = HPByBookie;
          window.scrollTo(0, 0);
          break;
        case 4:
          this.component = HPByNumber;
          window.scrollTo(0, 0);
          break;
      }
      // alert(id)
    },
  },
};
</script>

<style></style>
