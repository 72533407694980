<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :items="hp_list"
      item-key="n"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="75dvh"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn rounded id="menu-activator" color="info" dark>
            <v-icon left>
              mdi-chevron-down
            </v-icon>
            จัดการ
          </v-btn>

          <v-menu activator="#menu-activator" offset-y>
            <v-list>
              <AddData />
              <v-list-item
                @click="printSection"
                style="background-color: lightgreen;"
              >
                <v-list-item-title style="color: black;">
                  <v-icon left>
                    mdi-printer
                  </v-icon>
                  พิมพ์</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="ml-1"
            rounded
            outlined
            id="menu-activator"
            color="info"
            dark
          >
            <v-icon style="color: lightblue ;">
              mdi-bookmark
            </v-icon>
          </v-btn>

          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded dark color="pink" @click="ShowBetTypeDlg">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            {{ getBetTypeDetailNameFull(parseInt(BetType)) }}
          </v-btn>
        </v-toolbar>
      </template>

      <template slot="header">
        <thead>
          <tr>
            <th colspan="1" class="h_id" style="width: 120px;">
              เลข
            </th>
            <th colspan="1" class="text-center" :style="getColWidth()">
              {{ getNickname() }}
            </th>
            <th
              colspan="1"
              class="text-center"
              :style="getColWidth()"
              v-for="item in bookie_data"
              :key="item.id"
            >
              {{ item.Name }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'pink lighten-5': item.number === selectedId }"
        >
          <td :class="id_column">{{ item.number }}</td>

          <td
            align="center"
            v-for="index in bookie_count + 1"
            :key="index"
            @click="PriceClick(item.number, index)"
            :class="getHpClass(item.number, index)"
          >
            {{ getColumnText(item.number, index) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
    <BetTypeDlg @OnChangeBetType="ChangeInputBetType" ref="BetTypeDlg" />
  </div>
</template>

<script>
import betFunctionMixin from "@/mixins/betFunctionMixin";
import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import AddData from "@/components/Data/Export/Bookie/Genaral/Add_Data";
import BetTypeDlg from "@/components/Controls/BetTypeDlg";

export default {
  name: "Bookie_Datatable",

  mixins: [betFunctionMixin],

  components: {
    PopupMenu,
    AddData,
    BetTypeDlg,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bookie_data() {
      return this.$store.getters["bookie/Bookies"];
    },
    bookie_count() {
      return this.$store.getters["bookie/Bookies"].length;
    },
    hp_list() {
      // console.log(this.$store.getters["bookie/HpList"]);
      return this.$store.getters["bookie/HpList"];
    },
    hp_count() {
      return this.$store.getters["bookie/HpList"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        ListType: "hp_by_number",
        BetType: this.BetType,
      };
      await this.$store.dispatch("bookie/get_hp_bynumber", credentials).then(
        (response) => {
          //console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getColumnText(Number, ColumnID) {
      let EXPNUM = this.$store.getters["bookie/HpList"];
      for (let n = 0; n < EXPNUM.length; n++) {
        if (EXPNUM[n].number == Number) {
          const HP = EXPNUM[n][ColumnID - 1].split("/");
          if (HP[0] == 100) return "";
          else if (HP[0] == 50) return "จ่ายครึ่ง";
          else return HP[0] * -1;
        }
      }
      return "";
    },
    getHpClass(Number, ColumnID) {
      let EXPNUM = this.$store.getters["bookie/HpList"];
      for (let n = 0; n < EXPNUM.length; n++) {
        if (EXPNUM[n].number == Number) {
          const HP = EXPNUM[n][ColumnID - 1].split("/");
          if (HP[0] == 100) return "";
          else if (HP[1] == 1)
            return ColumnID == 1
              ? ["text-decoration-underline", "grey--text  text--darken-2"]
              : ["text-decoration-underline", "grey--text text--darken-2"];
          else
            return ColumnID == 1
              ? ["grey--text text--darken-2"]
              : ["grey--text text--darken-2"];
        }
      }
      return "";
    },
    getColWidth() {
      //console.log("width: "  + Math.floor(100 / (this.$store.getters["bookie/Bookies"].length + 1)) + "%");
      let w = Math.floor(
        100 / (this.$store.getters["bookie/Bookies"].length + 1)
      );
      if (w > 7) w = 7;
      return "width: " + w + "%;";
    },
    getStatusColor(status) {
      const statusColor = ["green--text", "red--text"];
      return statusColor[parseInt(status)] + " pl-10";
    },
    getNamesColor(status) {
      const statusColor = ["", "red--text"];
      return statusColor[parseInt(status)];
    },
    row_click: function(e, item) {
      if (this.selectedId == item.id) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },

    ShowBetTypeDlg() {
      this.$refs.BetTypeDlg.Show(1);
    },
    ChangeInputBetType(p_BetType) {
      this.BetType = p_BetType;
    },
    getNickname() {
      return sessionStorage.getItem("nickname");
    },
  },
  data: () => ({
    selectedId: -1,

    touch: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    BetType: "1",

    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #0166b9 !important;
  color: white;
  /* text-align: center !important;  
    padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #0166b9 !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 18px !important;
  border-right: 1px solid;
  border-top: 1px solid;
  border-color: lightgray;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1em !important;
    color: rgb(92, 92, 92);
    font-weight: 530;
  }
}
</style>
